/* Calendar.module.css */

/* Style FullCalendar events */
:global(.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard) {
    height: 100%;
}

:global(:root) {    
    --fc-button-bg-color: #35be00;
    --fc-button-border-color: #35be00;
    --fc-button-hover-bg-color: #2da000;
    --fc-button-hover-border-color: #35be00;
    --fc-button-active-bg-color: #2da000;
    --fc-button-active-border-color: #2da000;
    --fc-today-bg-color:#3eda0052;
}